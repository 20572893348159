<template>
  <div>
    <div class="container" align="left">
      <div class="row d-flex mt-5">
        <h3 class="mb-4"><strong>개인정보보호 처리방침</strong></h3>
        <p align="left">
          SK핀크스(주)(이하 '회사'라 함)는 고객의 개인정보를 매우 중요하게 생각하며, ‘개인정보보호법’ 등 관련법령을
          준수하고 있습니다. 회사는 개인정보 처리방침을 통하여 고객께서 제공하신 개인정보가 어떠한 용도와 방식으로
          처리되고 있고, 개인정보보호를 위하여 어떤 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보처리방침을
          개정하는 경우, 웹사이트 공지사항 (또는 개별공지)을 통하여 공지할 것입니다. 회사의 개인정보 처리방침은 다음과
          같은 내용을 담고 있습니다.
        </p>

        <div class="bookmark-links">
          <a href="#section-01">제 1조 개인정보의 처리 목적</a>
          <a href="#section-02">제 2조 개인정보의 수집 및 이용목적</a>
          <a href="#section-03">제 3조 개인정보의 처리기간</a>
          <a href="#section-04">제 4조 개인정보의 제3자 제공</a>
          <a href="#section-05">제 5조 개인정보 수탁업체 및 업무내용</a>
          <a href="#section-06">제 6조 개인정보의 파기절차 및 방법</a>
          <a href="#section-07">제 7조 정보주체의 권리 및 행사방법</a>
          <a href="#section-08">제 8조 쿠키에 의한 개인정보 수집</a>
          <a href="#section-09">제 9조 만 19세 미만 아동의 개인정보보호</a>
          <a href="#section-10">제 10조 개인정보 보호책임자</a>
          <a href="#section-11">제 11조 개인정보의 안전성 확보조치</a>
          <a href="#section-12">제 12조 영상정보처리기기 운영관리에 관한 사항</a>
          <a href="#section-13">제 13조 정보주체의 권익침해에 대한 구제방법</a>
          <a href="#section-14">제 14조 개인정보 처리방침의 변경에 관한 사항</a>
        </div>

        <div class="container">
          <div class="row privacy-detail mb-5">
            <section id="section-01" class="mt-5">
              <h5><strong>제 1조 개인정보의 처리 목적</strong></h5>
              <p>
                회사는 다음 각 항목과 같은 목적으로 고객의 개인정보를 처리하고 있습니다. 처리하고 있는 개인정보는 다음의
                목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의
                동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </p>
              <p class="numText1">1. 홈페이지, 멤버십 회원 가입 및 관리</p>
              <p class="innertext">
                회원 가입의사 확인, 회원 서비스 제공에 따른 본인 식별∙인증, 맴버십자격유지∙관리, 제한적 본인 확인제
                시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지∙통지, 처리결과 통보 등의 목적으로 개인정보를
                처리합니다.
              </p>

              <p class="numText1">2. 재화 또는 서비스 제공</p>
              <p class="innertext">
                물품배송, 예약 및 서비스 제공, 계약서∙청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증,
                요금결제∙정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
              </p>
              <p class="numText1">3. 고객문의 처리</p>
              <p class="innertext">
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락통지, 처리결과 등의 목적으로 개인정보를
                처리합니다.
              </p>
              <p class="numText1">4. 마케팅 및 광고활용</p>
              <p class="innertext">
                이벤트 등 광고성 정보 전달, 이벤트 당첨시 물품 배송, 신규 서비스 개발 및 특화, 인구통계학적 특성에 따른
                서비스 제공 및 광고 게재, 홈페이지 뉴스레터 발송, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
                목적으로 개인정보를 처리합니다.
              </p>
            </section>
            <section id="section-02" class="mt-5">
              <h5><strong>제 2조 개인정보의 수집 및 이용목적</strong></h5>
              <p>회사는 다음과 같은 목적을 위해 개인정보를 수집하고 있습니다.</p>

              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td width="15%">구분</td>
                    <td width="20%">수집항목</td>
                    <td>수집목적</td>
                    <td width="10%">수집방법</td>
                    <td width="10%">보유기간</td>
                  </tr>
                  <tr>
                    <td>멤버십</td>
                    <td>성명, 생년월일, 휴대폰번호, 이메일주소, 아이디, 비밀번호, 예약내역</td>
                    <td>
                      - 멤버십서비스에 대한 본인확인 절차<br />
                      - 멤버십 혜택, 약관변경, 고지사항 전달<br />
                      - 문의/상담 및 불만등에 대한 민원처리
                    </td>
                    <td>홈페이지</td>
                    <td>탈퇴 후 1년</td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      온라인예약<br />
                      (호텔)
                    </td>
                    <td>성명, 연락처</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>
                      1년<br />
                      (체크아웃기준)
                    </td>
                  </tr>
                  <tr>
                    <td>신용카드정보(신용카드종류, 카드번호, 카드유효기한)</td>
                    <td>객실 및 부대시설 이용요금에 대한 보증 및 서비스 제공</td>
                    <td>홈페이지</td>
                    <td>
                      30일<br />
                      (체크아웃기준)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      온라인예약<br />
                      (골프)
                    </td>
                    <td>성명, 연락처</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>
                      1년<br />
                      (이용종료일기준)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      온라인예약<br />
                      (다이닝)
                    </td>
                    <td>성명, 연락처</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>
                      1년<br />
                      (이용종료일기준)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      입실등록카드<br />
                      (호텔)
                    </td>
                    <td>성명, 휴대폰번호, 투숙기간, 객실번호, 차량번호</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리<br />
                      - 마케팅활용
                    </td>
                    <td>서면, 웹</td>
                    <td>30일</td>
                  </tr>
                  <tr>
                    <td>PTC</td>
                    <td>성명, 핸드폰번호, 레슨기간</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>서면, 웹</td>
                    <td>30일</td>
                  </tr>
                  <tr>
                    <td>P-Leisure</td>
                    <td>성명, 연락처, 이메일주소</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>1년</td>
                  </tr>
                  <tr>
                    <td>웨딩문의</td>
                    <td>성명, 연락처, 이메일주소</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>목적 달성 후 파기</td>
                  </tr>
                  <tr>
                    <td>행사문의</td>
                    <td>성명, 연락처, 이메일주소</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>목적 달성 후 파기</td>
                  </tr>
                  <tr>
                    <td>마케팅제휴 문의</td>
                    <td>성명, 연락처, 직책, 이메일주소</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>목적 달성 후 파기</td>
                  </tr>
                  <tr>
                    <td>고객의 소리</td>
                    <td>성명, 연락처, 이메일주소</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>홈페이지</td>
                    <td>민원처리 후 즉시 파기</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">멤버십</td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 생년월일, 휴대폰번호, 이메일주소, 아이디, 비밀번호, 예약내역</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 멤버십서비스에 대한 본인확인 절차<br />
                      - 멤버십 혜택, 약관변경, 고지사항 전달<br />
                      - 문의/상담 및 불만등에 대한 민원처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">탈퇴 후 1년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      온라인예약 (호텔)
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td>성명, 연락처</td>
                    <td>신용카드정보(신용카드종류, 카드번호, 카드유효기한)</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td>
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                    <td>객실 및 부대시설 이용요금에 대한 보증 및 서비스 제공</td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td>홈페이지</td>
                    <td>홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td>1년 (체크아웃기준)</td>
                    <td>30일 (체크아웃기준)</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      온라인예약 (골프)
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">1년 (이용종료일기준)</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      온라인예약 (다이닝)
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">1년 (이용종료일기준)</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      입실등록카드 (호텔)
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 휴대폰번호, 투숙기간, 객실번호, 차량번호</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리<br />
                      - 마케팅활용
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">서면, 웹</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">30일</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">PTC</td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 핸드폰번호, 레슨기간</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">서면, 웹</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">1년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      P-Leisure
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처, 이메일주소</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">1년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      웨딩문의
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처, 이메일주소</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">목적 달성 후 파기</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      행사문의
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처, 이메일주소</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">목적 달성 후 파기</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      마케팅제휴 문의
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처, 직책, 이메일주소</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 예약내역 및 안내고지<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">목적 달성 후 파기</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      고객의 소리
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">성명, 연락처, 이메일주소</td>
                  </tr>
                  <tr>
                    <td>수집목적</td>
                    <td colspan="2">
                      - 서비스이용자 식별 및 본인확인<br />
                      - 문의/상담 및 불만등에 대한 민원처리<br />
                      - 부정 이용 방지 및 법적 분쟁등의 처리
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td colspan="2">홈페이지</td>
                  </tr>
                  <tr>
                    <td>보유기한</td>
                    <td colspan="2">민원처리 후 파기</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1 font-weight-normal">
                ※ 서비스 이용과정에서 아래의 개인정보 항목이 생성되어 수집될 수 있습니다.<br />
                - IP주소, 쿠키, 서비스이용기록, 방문기록, 상담시 통화내역
              </p>
            </section>
            <section id="section-03" class="mt-5">
              <h5 class="mb-4"><strong>제 3조 개인정보의 처리기간</strong></h5>
              <p class="numText1">
                1. 회사는 "홈페이지" 탈퇴 요청이나 멤버십 회원의 개인정보 삭제 요청시에는 수집된 개인정보가 열람 또는
                이용될 수 없도록 (즉시) 처리합니다. 다만, 홈페이지 가입 이력관리를 위해 아이디는 보관됩니다.
              </p>
              <p class="numText1">
                2. 하지만, 해지 시 상법등 법령에 의하여 더 보존할 필요성이 있는 경우에는 법령에서 규정한 보존기한 동안
                거래내역과 최소한의 기본정보를 보유할 수 있으며 보유기간을 회원님에게 미리 고지하거나 개별적으로
                회원님의 동의를 받은 경우에는 약속한 보존기간동안 개인정보를 보유합니다. 이 경우 회사는 보유하는 정보를
                그 보유 목적으로만 이용하며, 보존기간은 아래와 같습니다.
              </p>
              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>구분</td>
                    <td>보존 이유</td>
                    <td width="20%">보존기간</td>
                  </tr>
                  <tr>
                    <td>계약 또는 청약철회 등에 관한 기록</td>
                    <td rowspan="3">전자상거래 등에서의 소비자 보호에 관한 법률</td>
                    <td rowspan="2">5년</td>
                  </tr>
                  <tr>
                    <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                  </tr>
                  <tr>
                    <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                    <td>3년</td>
                  </tr>
                  <tr>
                    <td>상업 장부와 영업에 관한 중요 서류</td>
                    <td>상법 제33조 (상업장부등의 보존)</td>
                    <td>10년</td>
                  </tr>
                  <tr>
                    <td>채권, 재산권에 관한 기록</td>
                    <td>민법 제162조 (채권, 재산권 소멸시효)</td>
                    <td>10년</td>
                  </tr>
                  <tr>
                    <td>기타소득관련 경비 등의 지출증명서류</td>
                    <td>소득세법 제106조의 2(경비 등의 지출증빙 수취 및 보관)</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>세법이 규정하는 장부 및 증빙서류</td>
                    <td>국세기본법</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>전자금융 거래에 관한 기록</td>
                    <td>전자금융거래법</td>
                    <td>5년</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>계약 또는 청약철회 등에 관한 기록</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>5년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>5년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>3년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>상업 장부와 영업에 관한 중요 서류</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>상법 제33조 (상업장부등의 보존)</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>10년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>채권, 재산권에 관한 기록</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>민법 제162조 (채권, 재산권 소멸시효)</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>10년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>기타소득관련 경비 등의 지출증명서류</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>소득세법 제106조의 2(경비 등의 지출증빙 수취 및 보관)</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>5년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>세법이 규정하는 장부 및 증빙서류</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>국세기본법</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>5년</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">구분</td>
                    <td>전자금융 거래에 관한 기록</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존 이유</td>
                    <td>전자금융거래법</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보존기간</td>
                    <td>5년</td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section id="section-04" class="mt-5">
              <h5 class="mb-4"><strong>제 4조 개인정보의 제3자 제공</strong></h5>
              <p class="numText1">
                1. 회사는 정보주체의 개인정보를 '개인정보의 처리목적', '수집하는 개인정보 항목 및 수집방법' 에서 명시한
                범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에
                해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지
                않습니다.
              </p>
              <p class="numText2">가. 정보주체로부터 별도의 동의를 받은 경우</p>

              <p class="numText2">나. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</p>

              <p class="numText2">
                다. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을
                수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고
                인정되는 경우
              </p>
              <p class="numText2">
                라. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관
                업무를 수행할 수 없는 경우로서 보호위원회의 심의-의결을 거친 경우
              </p>
              <p class="numText2">
                마. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우
              </p>
              <p class="numText2">바. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</p>
              <p class="numText2">사. 법원의 재판업무 수행을 위하여 필요한 경우</p>
              <p class="numText2">아. 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</p>

              <p class="numText1">
                2. 회사는 재난, 감염병, 급박한 생명∙신체 위험을 초래하는 사건∙사고. 급박한 재산 손실 등의 긴급상황이
                발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 이 경우 회사는 근거법령에
                의거하여 필요한 최소한의 개인정보만을 제공하며, 목적과 다르게 제공하지 않겠습니다.
              </p>

              <p class="numText1">3. 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</p>
              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>제공받는자</td>
                    <td>제공목적</td>
                    <td>제공항목</td>
                    <td width="20%">보유 및 이용기한</td>
                  </tr>
                  <tr>
                    <td>국세청</td>
                    <td>골프회원권 명의개서</td>
                    <td>성명, 주민등록번호, 주소, 회원번호, 입회일자, 취득가, 매매가</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>골프장경영협회</td>
                    <td>회원증서 발행</td>
                    <td>성명, 회원번호, 입회일자</td>
                    <td>회원 탈회시까지</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">제공받는자</td>
                    <td>국세청</td>
                    <td>골프장경영협회</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">제공목적</td>
                    <td>골프회원권 명의개서</td>
                    <td>회원증서 발행</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">제공항목</td>
                    <td>성명, 주민등록번호, 주소, 회원번호, 입회일자, 취득가, 매매가</td>
                    <td>성명, 회원번호, 입회일자</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">보유 및 이용기한</td>
                    <td>5년</td>
                    <td>회원 탈회시까지</td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section id="section-05" class="mt-5">
              <h5 class="mb-4"><strong>제 5조 개인정보 수탁업체 및 업무내용</strong></h5>
              <p class="numText1">
                1. 회사는 개인정보 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약시
                개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
              </p>
              <table class="table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>수탁업체</td>
                    <td>수탁업무내용</td>
                  </tr>
                  <tr>
                    <td>SK네트웍스서비스㈜</td>
                    <td>홈페이지 운영 및 관리</td>
                  </tr>
                  <tr>
                    <td>비즈톡 주식회사</td>
                    <td>문자, 알림톡 발송 서비스</td>
                  </tr>
                  <tr>
                    <td>SCI평가정보㈜</td>
                    <td>휴대폰을 통한 본인인증</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1">
                2. 회사는 위탁계약 체결시 개인정보보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지,
                기술적∙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등
                문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
              </p>
              <p class="numText1">
                3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록
                하겠습니다. 회사는 회원님의 서비스 유지 및 관리, 고객상담, 기타 서비스 안내를 위하여 전문 용역 업체에
                일부 업무를 위탁 운영할 수 있습니다. 위탁을 받은 업체는 위탁을 받은 목적을 벗어나서 개인정보를 이용할 수
                없습니다. 회사는 위탁처리되는 개인정보가 안전하게 관리될 수 있도록 수탁업체가 규정한 사항을 성실하게
                이행하는 지 여부에 대하여 위탁한 업무의 범위내에서 적절한 감독을 행할 것입니다.
              </p>
            </section>

            <section id="section-06" class="mt-5">
              <h5 class="mb-4"><strong>제 6조 개인정보의 파기절차 및 방법</strong></h5>
              <p class="numText1">
                1. 회사는 개인정보의 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
                개인정보를 파기합니다.
              </p>
              <p class="numText1">
                2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
                따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
                보관장소를 달리하여 일정기간 보관된 후 파기됩니다. 별도 데이터베이스 (종이문서의 경우 별도의 서류함)로
                옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.
              </p>

              <p class="numText1">3. 개인정보의 파기절차, 파기기한, 파기방법은 다음과 같습니다.</p>
              <p class="numText2">1) 파기절차</p>
              <p class="numText3">
                회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를
                파기합니다.
              </p>
              <p class="numText2">2) 파기방법</p>
              <p class="numText3">
                회사는 전자적 파일 형태로 기록∙저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이문서에
                기록∙저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
              </p>
              <p class="numText2">3) 파기기한</p>
              <p class="numText3">
                정보주체의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 파기하며
                개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
                개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
              </p>
            </section>

            <section id="section-07" class="mt-5">
              <h5 class="mb-4"><strong>제 7조 정보주체 권리 및 행사방법</strong></h5>
              <p class="numText1">
                1. 정보주체는 회사에 대해 언제든지 다음과 같이 개인정보보호 관련 권리를 행사할 수 있습니다.
              </p>
              <p class="numText2">가. 개인정보 열람 요구</p>
              <p class="numText2">나. 오류 등이 있을 경우 정정 요구</p>
              <p class="numText2">다. 삭제 요구</p>
              <p class="numText2">라. 처리 정지 요구</p>
              <p class="numText1">
                2. 제1항에 따른 권리행사는 회사에 대해 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전자우편,
                모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다. (개인정보 처리에
                관한 고시(제2020-7호) 별지 제8호, 제10호 서식 참조)
              </p>
              <p class="numText1">
                3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할
                때까지 해당 개인정보를 이용하거나 제공하지 않습니다.
              </p>
              <p class="numText1">
                4. 제1항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도
                있습니다. 이 경우 "개인정보 처리 방법에 관한 고시(제2020-7호)" 별지 제11호 서식에 따른 위임장을
                제출하셔야 합니다.
              </p>
              <p class="numText1">
                5. 개인정보 열람 및 처리정지 요구는 개인정보보호법에 제35조 제4항, 제37조 제2항에 의하여 정보주체의
                권리가 제한될 수 있습니다.
              </p>
              <p class="numText1">
                6. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그
                삭제를 요구할 수 없습니다.
              </p>
              <p class="numText1">
                7. 회사는 정보주체 권리에 따른 열람의 요구, 정정∙삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
                본인이거나 정당한 대리인인지를 확인합니다.
              </p>
            </section>

            <section id="section-08" class="mt-5">
              <h5 class="mb-4">
                <strong>제 8조 쿠키에 의한 개인정보수집</strong>
              </h5>
              <p class="numText1">
                1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는
                '쿠키(cookie)'를 사용합니다.
              </p>
              <p class="numText1">
                2. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
                이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
              </p>
              <p class="numText2">가. 쿠키(cookie)의 운용</p>
              <p class="numText3">
                이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용행태, 인기검색어, 보안접속 여부 등을 파악하여
                이용자에게 최적화된 정보 제공을 위해 사용됩니다.
              </p>
              <p class="numText2">나. 쿠키(cookie)의 설치/운영 및 거부</p>
              <p class="numText3">
                이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우져에서 옵션을 설정하여 모든 쿠키를 허용하거나,
                쿠키가 저장될 때마다 확인을 거치게 하거나 아니면 쿠기의 저장을 거부할 수 있습니다.
              </p>
              <p class="numText2">
                다. 쿠키(cookie) 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
              </p>
            </section>

            <section id="section-09" class="mt-5">
              <h5 class="mb-4">
                <strong>제 9조 만19세 미만 아동의 개인정보보호</strong>
              </h5>
              <p>회사는 만19세 미만 아동의 개인정보를 수집하지 않으며, 회원 가입이 불가합니다.</p>
            </section>

            <section id="section-10" class="mt-5">
              <h5 class="mb-4">
                <strong>제 10조 개인정보 보호책임자</strong>
              </h5>
              <p>
                회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
                피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 개인정보와 관련된 문의사항이
                있으시면 아래의 개인정보 보호책임자/보호담당자에게 연락주시기 바랍니다. 회사는 정보주체의 문의에 대해
                지체없이 답변 및 처리해드릴 것입니다.
              </p>
              <p class="numText2">
                <strong>개인정보보호 책임자</strong><br />
                - 성명 : 채진혁<br />
                - 소속 : 마케팅팀<br />
                - 직책 : 마케팅팀 팀장<br />
                - 이메일 : chaejh0425@sk.com<br />
                - 전화번호 : 064-793-6050<br /><br />
              </p>
              <p class="numText2">
                <strong>개인정보보호 담당자</strong><br />
                - 성명 : 오예슬<br />
                - 소속 : ESG TF<br />
                - 직책 : ESG TF 매니저<br />
                - 이메일 : yeseul12@sk.com<br />
                - 전화번호 : 064-793-5016
              </p>
            </section>

            <section id="section-11" class="mt-5">
              <h5 class="mb-4">
                <strong>제 11조 개인정보의 안전성 확보조치</strong>
              </h5>
              <p>
                회사는 개인정보보호법 제29조에 따라 다음과 같이 안정성 확보에 필요한 기술적/관리적 및 물리적 조치를
                취하고 있습니다.
              </p>
              <p class="numText1">1. 개인정보 처리 인력의 최소화</p>
              <p class="innerText">개인정보보호를 위해 개인정보 처리자에 대한 권한 부여를 최소화하고 있습니다.<br /></p>
              <p class="numText1">2. 개인정보 처리 인력에 대한 정기적 교육 시행</p>
              <p class="innerText">개인정보보호에 대한 인식 제고를 위해 정기적인 교육을 시행하고 있습니다.<br /></p>
              <p class="numText1">3. 내부 점검 정기적 시행</p>
              <p class="innerText">
                개인정보의 처리 관련 안정성 확보를 위해 정기적으로 자체 점검을 실시하고 있습니다.<br />
              </p>
              <p class="numText1">4. 내부 관리계획의 수립 및 시행</p>
              <p class="innerText">
                개인정보의 안전한 처리 및 관리를 위해 내부관리계획을 수립하여 관리하고 있습니다.<br />
              </p>
              <p class="numText1">5. 개인정보의 암호화</p>
              <p class="innerText">
                이용자의 개인정보와 비밀번호는 암호화되어 저장/관리되고 있으며 전송 시에도 별도의 보안기능을 사용하여
                안전하게 관리하고 있습니다.<br />
              </p>
              <p class="numText1">6. 해킹 등에 대한 기술적 대책</p>
              <p class="innerText">
                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고
                주기적인 갱신/점검을 하여 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로 감시 및
                차단하고 있습니다.<br />
              </p>
              <p class="numText1">7. 개인정보에 대한 접근 제한</p>
              <p class="innerText">
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한
                접근통제를 위하여 필요한 조치를 하고 있으며 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고
                있습니다.<br />
              </p>
              <p class="numText1">8. 접속기록의 보관 및 위변조 방지</p>
              <p class="innerText">
                개인정보 처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며 접속 기록이 위변조 및 도난 분실
                되지 않도록 보안기능을 사용하고 있습니다.<br />
              </p>
              <p class="numText1">9. 문서보안을 위한 잠금장치 사용</p>
              <p class="innerText">
                개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br />
              </p>
              <p class="numText1">10. 비인가자에 대한 출입 통제</p>
              <p class="innerText">
                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고
                있습니다.<br />
              </p>
              <p class="numText1">
                11. 정보주체 본인의 부주의나 인터넷 상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해
                회사는 일체의 책임을 지지 않습니다.
              </p>
              <p class="numText1">
                12. 회사는 개인정보의 유출∙훼손 등 개인정보 침해사고 발생시 지체없이 정보주체에게 알리고 그 피해를
                최소화 하기 위해 필요한 조치를 다합니다.
              </p>
            </section>

            <section id="section-12" class="mt-5">
              <h5 class="mb-4">
                <strong>제 12조 영상정보처리기기 운영관리에 관한 사항</strong>
              </h5>
              <p>회사는 아래와 같이 영상정보처리기기를 설치운영하고 있습니다.</p>
              <p class="numText1">1. 영상정보처리기기의 설치근거 및 설치 목적</p>
              <p class="innerText">
                회사에 출입하는 고객/구성원(직원 및 협력사)의 안전과 회사의 자산을 보호하고 범죄 및 각종 사고의
                증거자료, 차량 도난 및 파손 방지, 화재 등 재난으로부터 시설/이용객의 안전확보 등 사실 증빙 자료로 활용할
                목적으로 합니다.
              </p>
              <p class="numText1">2. CCTV 정보</p>
              <table class="table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td width="20%">설치대수</td>
                    <td>설치 위치 및 촬영범위</td>
                  </tr>
                  <tr>
                    <td>61대</td>
                    <td>골프장 클럽하우스 건물 내외부, 골프장 코스, 주차장</td>
                  </tr>
                  <tr>
                    <td>8대</td>
                    <td>후생관 건물 내외부, 주차장</td>
                  </tr>
                  <tr>
                    <td>25대</td>
                    <td>포도호텔 건물 내외부, 주차장</td>
                  </tr>
                  <tr>
                    <td>47대</td>
                    <td>디아넥스 건물 내외부, 주차장</td>
                  </tr>
                  <tr>
                    <td>16대</td>
                    <td>커뮤니티센터 건물 내외부, 주차장</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1">3. 총괄책임자 및 운영책임자</p>
              <p class="innerText">
                영상정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인영상정보 보호책임자를 두고
                있습니다.
              </p>
              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>구분</td>
                    <td>이름</td>
                    <td>직위</td>
                    <td>소속</td>
                    <td>연락처</td>
                  </tr>
                  <tr>
                    <td>총괄책임자</td>
                    <td>강창협</td>
                    <td>팀장</td>
                    <td>Infra 지원팀</td>
                    <td>064-793-6060</td>
                  </tr>
                  <tr>
                    <td>운영책임자</td>
                    <td>신창진</td>
                    <td>파트장</td>
                    <td>Infra 2파트</td>
                    <td>064-793-6061</td>
                  </tr>
                </tbody>
              </table>
              <table class="mobile table table-bordered">
                <tbody align="center">
                  <tr>
                    <td>구분</td>
                    <td>총괄책임자</td>
                    <td>운영책임자</td>
                  </tr>
                  <tr>
                    <td>이름</td>
                    <td>강창협</td>
                    <td>신창진</td>
                  </tr>
                  <tr>
                    <td>직위</td>
                    <td>팀장</td>
                    <td>파트장</td>
                  </tr>
                  <tr>
                    <td>소속</td>
                    <td>Infra 지원팀</td>
                    <td>Infra 2파트</td>
                  </tr>
                  <tr>
                    <td>연락처</td>
                    <td>064-793-6060</td>
                    <td>064-793-6061</td>
                  </tr>
                </tbody>
              </table>
              <p class="numText1">4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법</p>

              <table class="table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td width="20%">촬영시간</td>
                    <td width="30%">보관기간</td>
                    <td>보관장소</td>
                  </tr>
                  <tr>
                    <td rowspan="2">24 시간</td>
                    <td>촬영일로부터 15일</td>
                    <td>
                      골프장 클럽하우스 사무실, 후생관 통신실,<br />
                      포도호텔 사무실, 커뮤니티센터 감시실
                    </td>
                  </tr>
                  <tr>
                    <td>촬영일로부터 30일</td>
                    <td>디아넥스 방재실</td>
                  </tr>
                </tbody>
              </table>
              <p style="margin-left: 5rem; text-indent: -4.8rem">
                처리방법 : 개인영상정보의 목적외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 기록∙관리하고,
                보관기간 만료시 복원이 불가능한 방법으로 영구 삭제 (출력물의 경우 파쇄 또는 소각)합니다.
              </p>

              <p class="numText1">5. 영상정보의 확인 방법 및 장소에 관한 사항</p>
              <p class="numText2">
                - 확인방법 : 개인영상정보 보호책임자에게 미리 연락하고 당사를 방문하시면 확인 가능합니다.
              </p>
              <p class="numText2">- 확인장소 : Infra 지원팀</p>
              <p class="numText1">6. 정보주체의 영상정보 열람 등 요구에 대한 조치</p>
              <p class="innerText">
                개인영상정보에 관하여 열람 또는 존재 확인, 삭제를 원하는 경우 언제든지 당사에 요구하실 수 있습니다. 단,
                귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한
                개인영상정보에 한정됩니다. 당사는 개인영상정보에 관하여 열람 또는 존재 확인, 삭제를 요구한 경우 해당
                요구를 검토하여 지체없이 필요한 조치를 취할 것이며 불가피하에 해당 요구를 거부하는 경우에는 그 사유를
                통지하도록 하겠습니다.
              </p>
              <p class="numText1">7. 영상정보의 안정성 확보조치</p>
              <p class="innerText">
                당사는 폐쇄 네트워크 또는 방화벽 등의 보안시스템을 통하여 고객의 영상정보를 보호하고 있습니다. 또한,
                개인영상정보 보호를 위하여 해당 영상정보를 취급하는 인원을 최소한으로 제한하고 있으며 지속적인 교육을
                시행하고 있습니다. 개인영상정보의 임의적인 취급을 제한하기 위하여 개인영상정보의 열람 시 열람 목적,
                열람자, 열람 일시 등을 기록, 관리하고 있으며 영상정보의 안전한 물리적 보관을 위하여 잠금장치를 설치하고
                있습니다. 보안구역으로 정하고 사전 승인된 인력 이외에는 출입을 통제하고 있습니다.
              </p>
            </section>

            <section id="section-13" class="mt-5">
              <h5 class="mb-4">
                <strong>제 13조 정보주체의 권익침해에 대한 구제방법</strong>
              </h5>
              <p>
                정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
                개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고,
                상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
              </p>
              <p class="numText1">1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
              <p class="numText2">- 소관업무 : 개인정보 침해사실 신고, 상담신청</p>
              <p class="numText2">- 홈페이지 : privacy.kisa.co.kr</p>
              <p class="numText2">- 전화 : (국번없이) 118</p>
              <p class="numText2">- 주소 : (58324) 전남 나주시 진흥길 9(빛가림동 301-2) 3층 개인정보침해신고센터</p>
              <p class="numText1">2. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</p>
              <p class="numText2">- 소관업무 : 개인정보 분쟁조정 신청, 집단분쟁조정 (민사적 해결)</p>
              <p class="numText2">- 홈페이지 : www.kopico.go.kr</p>
              <p class="numText2">- 전화 : (국번없이) 1833-6972</p>
              <p class="numText2">- 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층</p>
              <p class="numText1">3. 대검찰청 사어버수사과</p>
              <p class="numText2">- 전화 : (국번없이) 1301</p>
              <p class="numText2">- 홈페이지 : www.spo.go.kr</p>
              <p class="numText1">4. 경찰청 사이버수사국</p>
              <p class="numText2">- 전화 : (국번없이) 182</p>
              <p class="numText2">- 홈페이지 : ecrm.cyber.go.kr</p>
            </section>
            <section id="section-14" class="mt-5">
              <h5 class="mb-4">
                <strong>제 14조 개인정보 처리방침의 변경에 관한 사항 </strong>
              </h5>
              <p>
                개인정보 처리방침은 2013년 9월1일에 제정되었으며, 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제
                및 수정이 있을 시에는 변경되는 개인정보처리방침은 "홈페이지"를 통해 변경 이유 및 내용 등을 공지하도록
                하겠습니다.
              </p>
              <p class="innerText">- 최초 공고일자 : 2013년 09월 01일</p>
              <p class="innerText">- 변경일자 : 2018년 11월 23일</p>
              <p class="innerText">- 시행일자 : 2023년 08월 16일</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacyinfo',
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '개인정보 처리 방침'});
  },
};
</script>

<style scoped lang="scss">
p,
h1,
h2,
h3,
h4,
h5,
td {
  word-break: keep-all;
}
.bookmark-links a {
  float: left;
  display: block;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  font-size: 0.8rem;
  box-sizing: border-box;
}
td {
  vertical-align: middle;
}
a {
  color: #333;
}

.numText1 {
  margin-left: 1rem;
  text-indent: -1rem;
  font-weight: bold;
}
.numText2 {
  margin-left: 2.5rem;
  text-indent: -1.5rem;
}
.numText3 {
  margin-left: 2.5rem;
  text-indent: 0rem;
}
.innerText {
  margin: 1rem;
}
table_line_align {
  font-size: 0.88rem;
  margin-left: 1rem;
  text-indent: -1rem;
}

@media (min-width: 1200px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links a {
    width: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links a {
    width: 49%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links a {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links a {
    width: 100%;
  }
}
</style>
